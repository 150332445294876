import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import highchartsMap from 'highcharts/modules/map';

highchartsMap(Highcharts);

const PieChart = ({ numOver18, numUnder18 }) => {
  const series = [
    {
      name: 'Cases',
      colorByPoint: true,
      total: numOver18 + numUnder18,
      data: [
        {
          name: 'Underage',
          y: numUnder18,
        },
        {
          name: '18-25',
          y: numOver18,
          dataLabels: {
            enabled: false,
          },
        },
      ],
    },
  ];

  const plotOptions = {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      title: {
        enabled: false,
      },
      dataLabels: {
        enabled: true,
        format: '<b>{total}</b>',
        connectorColor: '#2F3640',
        distance: 2,
        style: {
          fontSize: '15px',
        },
        crop: false,
        overflow: 'allow',
      },
    },
  };

  const options = {
    chart: {
      backgroundColor: null,
      borderColor: null,
      plotBorderColor: null,
      plotBorderWidth: 100,
      plotShadow: false,
      type: 'pie',
      height: 100,
      width: 120,
      marginRight: 45,
    },

    colors: ['#770303', '#2F3640'],
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    title: {
      enabled: false,
      text: '',
    },

    tooltip: {
      headerFormat: '<b>{series.name}</b><br/>',
      pointFormat: '{point.name}: <b>{point.y}</b>',
      outside: true,
    },
    plotOptions,
    xAxis: {
      type: 'datetime',
      labels: { style: { fontSize: '8px' } },
      tickLength: 5,
    },

    series,
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export { PieChart };
