import React from 'react';
import PropTypes from 'prop-types';

const UsersAwardedLevels = ({ usersAwardLevels = [], possibleAwardLevels = [] }) => {
  const getAwardLevel = id => {
    return possibleAwardLevels.find(level => level.id === id);
  };

  return (
    <table className="award-levels-grid table-auto w-full">
      {/* Header for award levels */}
      <thead className="text-left">
        <tr>
          <th className="light-primary-blue-text font-semibold">Leads</th>
          <th className="light-primary-blue-text font-semibold">Date</th>
        </tr>
      </thead>
      <tbody>
        {usersAwardLevels.map(({ key, value }, index) => {
          const { points } = getAwardLevel(key);
          return (
            // <div key={key} className="award-level flex flex-row">
            <tr key={key}>
              <td title={`Lead level id: ${key}`}>{points} Leads</td>
              <td>
                <span title={value}>{new Date(value).toLocaleString()}</span>
              </td>
            </tr>
            // </div>
          );
        })}
      </tbody>
    </table>
  );
};

UsersAwardedLevels.propTypes = {
  possibleAwardLevels: PropTypes.array.isRequired,
  usersAwardLevels: PropTypes.array.isRequired,
};

export default UsersAwardedLevels;
