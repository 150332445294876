export const UserGroups = {
  Admin: 'Admin',
  Employee: 'Employee',
  Certified: 'Certified',
};

export const UserTypes = {
  Admin: 'ADMIN',
  Staff: 'STAFF',
  Volunteer: 'VOLUNTEER',
  LEO: 'LEO',
};

export const CaseStatus = {
  Active: 'ACTIVE',
  Approved: 'APPROVED',
  Submitted: 'SUBMITTED',
  Resubmitted: 'RESUBMITTED',
  Returned: 'RETURNED',
  Nullified: 'NULLIFIED',
};

export const TutorialCategory = {
  Proj1591HowTo: 'PROJECT_1591_HOW_TO',
  OSINTTipsTricks: 'OSINT_TIPS_TRICKS',
  Miscellaneous: 'MISCELLANEOUS',
};

export const VolunteerStatus = {
  Uncertfied: 'UNCERTIFIED',
  Certified: 'CERTIFIED',
  Pending: 'PENDING',
  Disabled: 'DISABLED',
  Deleted: 'DELETED',
};

export const EmployeeType = {
  Admin: 'ADMIN',
  Staff: 'STAFF',
};

export const ESCORT_AD = 'ESCORT_AD';

export const AwardClassificationOptions = {
  Underage: 'UNDERAGE',
  Adult: 'ADULT',
  Unknown: 'UNKNOWN',
};

export const AwardTypes = {
  AccountIdentified: 'ACCOUNT_IDENTIFIED',
  VerficationSource: 'VERIFICATION_SOURCE',
  NoPoints: 'NO_POINTS',
};
