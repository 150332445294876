import React, { useState, useContext, useEffect, useCallback } from 'react';
const pointLevels = [
  { level: 1, points: 3, id: 'f96fd95c-a9f4-421d-937d-1e01c20b84ef' },
  { level: 2, points: 7, id: 'd9f3222b-1c41-4507-9b7e-b5e340d03e26' },
  { level: 3, points: 12, id: '1688954f-ea9b-4791-b997-45eba6d8e5c5' },
  { level: 4, points: 25, id: '053c9c19-1755-4354-bfaa-bf75d993bb53' },
];

export const useAwardLevels = () => {
  const [awardLevels, setAwardLevels] = useState([]);

  const getAwardLevels = async () => pointLevels;

  useEffect(() => {
    getAwardLevels().then(levels => setAwardLevels(levels));
  }, [getAwardLevels]);

  return {
    awardLevels,
    getAwardLevels,
  };
};
