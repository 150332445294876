import React, { useState, useEffect, useContext, memo, useMemo, useCallback } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GoogleMap, useJsApiLoader, MarkerClusterer, OverlayView, OverlayViewF, Marker } from '@react-google-maps/api';
import { useUser } from '../../contexts/userContext';
import { NotificationContext } from '../../helpers/AlertContext/AlertContext.js';

import { getCaseMapData } from '../../generated/graphql/queries';
import { PieChart } from '../PieChart';

const UNDERAGE_MARKER_COLOR = '#770303';
const OVER18_MARKER_COLOR = '#2F3640';

const CaseMap = ({ board = 'allTime', userCrowdToggle = 'crowd' }) => {
  const { user } = useUser();
  const [zoom] = useState(3);
  const [{ userPoints, crowdPoints, userOver18Points, crowdOver18Points }, setPointData] = useState({
    userPoints: [],
    crowdPoints: [],
    userOver18Points: [],
    crowdOver18Points: [],
  });
  const [center] = useState({
    lat: 39.8097343,
    lng: -98.5556199,
  }); // center of USA
  const [currClusters, setCurrClusters] = useState([]);
  const [, setAlert] = useContext(NotificationContext);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  useEffect(() => {
    // updatePoints();

    const updatePoints = async () => {
      try {
        const getId = userCrowdToggle === 'crowd' ? null : user.id;

        const {
          data: {
            getCaseMapData: { userCases, crowdCases, userOver18Cases, crowdOver18Cases },
          },
        } = await API.graphql(graphqlOperation(getCaseMapData, { board, id: getId }));

        const toLatLng = ({ lat, lon }) => ({ lat, lng: lon });
        const toOver18 = ({ lat, lng }) => ({ lat, lng, isOver18: true });
        const toUnder18 = ({ lat, lng }) => ({ lat, lng, isOver18: false });

        const newPointData = {
          userPoints: userCases.map(toLatLng).map(toUnder18),
          crowdPoints: crowdCases.map(toLatLng).map(toUnder18),
          userOver18Points: userOver18Cases.map(toLatLng).map(toOver18),
          crowdOver18Points: crowdOver18Cases.map(toLatLng).map(toOver18),
        };

        setPointData(newPointData);
      } catch (error) {
        console.error('Error fetching Case Map Data', error);

        setAlert({
          type: 'SET_NOTIFICATION',
          payload: {
            occurs: true,
            message: 'Error fetching Case Map Data',
            textColor: 'redText',
            borderColor: 'redBorder',
          },
        });
      }
    };

    updatePoints();
  }, [board, setAlert, user.id, userCrowdToggle]);

  const toMarkers = (location, pinColor, clusterer, isOver18, index = 0) => {
    const pinSVGHole =
      'M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z';

    const markerImage = {
      path: pinSVGHole,
      anchor: new window.google.maps.Point(12, 17),
      fillOpacity: 1,
      fillColor: pinColor,
      strokeWeight: 2,
      strokeColor: 'white',
      scale: 2,
      isOver18,
    };

    return <Marker key={createKey(location, index)} position={location} clusterer={clusterer} icon={markerImage} />;
  };

  const renderMarkers = () => {
    if (userCrowdToggle === 'crowd') {
      return (
        <>
          {userPoints.length + userOver18Points.length + crowdPoints.length + crowdOver18Points.length > 0 ? (
            <MarkerClusterer options={{ imagePath: undefined }} onClusteringEnd={onClusteringEnd}>
              {clusterer =>
                [...userPoints, ...userOver18Points, ...crowdPoints, ...crowdOver18Points].map(
                  ({ lat, lng, isOver18 }, index) => {
                    const markerColor = isOver18 ? OVER18_MARKER_COLOR : UNDERAGE_MARKER_COLOR;

                    return toMarkers({ lat, lng }, markerColor, clusterer, isOver18, index);
                  }
                )
              }
            </MarkerClusterer>
          ) : null}
        </>
      );
    }

    return (
      <>
        {userPoints.length + userOver18Points.length > 0 ? (
          <MarkerClusterer options={{ imagePath: undefined }} onClusteringEnd={onClusteringEnd}>
            {clusterer =>
              [...userPoints, ...userOver18Points].map(({ lat, lng, isOver18 }) => {
                const markerColor = isOver18 ? OVER18_MARKER_COLOR : UNDERAGE_MARKER_COLOR;

                return toMarkers({ lat, lng }, markerColor, clusterer, isOver18);
              })
            }
          </MarkerClusterer>
        ) : null}
      </>
    );
  };

  // Create Pie Charts
  const onClusteringEnd = item => {
    const newClusters = [];

    item.clusters.forEach(i => {
      if (i.markers.length < 2) return;
      let numOver18 = 0;
      let numUnder18 = 0;

      const lat = i.center.lat();
      const lng = i.center.lng();

      i.markers.forEach(m => {
        if (m.icon.isOver18) {
          numOver18++;
        } else {
          numUnder18++;
        }
      });

      newClusters.push({ lat, lng, numOver18, numUnder18 });
    });

    setCurrClusters(newClusters);
  };

  const renderClusters = useCallback(() => {
    const getPixelPositionOffset = (width, height) => ({
      x: -(width / 2),
      y: -(height / 2),
    });
    return currClusters.map((item, index) => (
      <Marker
        key={createKey({ lat: item.lat, lng: item.lng }, index)}
        position={{ lat: item.lat, lng: item.lng }}
        icon={{
          path: '',
        }}>
        <OverlayViewF
          key={createKey({ lat: item.lat, lng: item.lng }, index)}
          position={{ lat: item.lat, lng: item.lng }}
          // mapPaneName={OverlayView.FLOAT_PANE}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          getPixelPositionOffset={getPixelPositionOffset}>
          <PieChart numOver18={item.numOver18} numUnder18={item.numUnder18} />
        </OverlayViewF>
      </Marker>
    ));
  }, [currClusters]);

  function createKey(location, index = 0) {
    return location.lat + location.lng + index;
  }

  return isLoaded ? (
    <>
      <GoogleMap mapContainerStyle={{ flexGrow: '1', minHeight: '350px', height: '100%' }} center={center} zoom={zoom}>
        {renderClusters()}
        {renderMarkers()}
      </GoogleMap>

      <div className="flex justify-center">
        <div className="flex items-center">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="12" height="12" fill={UNDERAGE_MARKER_COLOR} />
          </svg>{' '}
          <span className="ml-1">Underage</span>
        </div>
        <div className="flex items-center ml-4">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="12" height="12" fill={OVER18_MARKER_COLOR} />
          </svg>
          <span className="ml-1">18-25</span>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default memo(CaseMap);
