import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as FiIcons from 'react-icons/fi';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { parseMarkdown } from '../../helpers/markdown';
import { useHistory } from 'react-router-dom';

const NotificationRow = ({ notification }) => {
  const history = useHistory();
  let link = null;
  const content = parseMarkdown(notification.message);
  const getIcon = () => {
    switch (notification.type) {
      case 'CASE_SUBMITTED':
        return <FaIcons.FaBriefcaseMedical size="40" />;

      case 'CASE_RESUBMITTED':
        return <FaIcons.FaBriefcaseMedical size="40" />;

      case 'CASE_APPROVED':
        return <FaIcons.FaAward size="40" />;

      case 'CASE_RETURNED':
        return <FaIcons.FaThumbsDown size="40" />;

      case 'CASE_ASSIGNED':
        return <FaIcons.FaBriefcase size="40" />;

      case 'CERTIFICATION_SUBMITTED':
        return <FaIcons.FaUserPlus size="40" />;

      case 'STAFF_MESSAGE':
        return (
          <span className="fa-layers fa-fw fa-3x">
            <FontAwesomeIcon icon={icon({ name: 'message', style: 'regular' })} />
            <FontAwesomeIcon
              icon={icon({ name: 'triangle-exclamation', style: 'solid' })}
              transform={'shrink-5 up-7 right-7'}
              // mask={icon({ name: 'triangle', style: 'solid' })}
            />
          </span>
        );

      case 'MESSAGE_RECEIVED':
        return (
          <span className="fa-layers fa-fw fa-3x">
            {/* <FaIcons.FaEnvelope size={20} /> */}
            <FontAwesomeIcon icon={icon({ name: 'envelope', style: 'solid' })} />
          </span>
        );

      case 'CASE_FILE_DOWNLOADED':
        return (
          <span className="fa-layers fa-fw fa-3x">
            <FontAwesomeIcon icon={icon({ name: 'arrow-down-to-bracket', style: 'light', family: 'sharp' })} />
          </span>
        );

      case 'AWARD_THRESHOLD_REACHED':
        return (
          <span className="fa-layers fa-fw fa-3x">
            <FontAwesomeIcon icon={icon({ name: 'trophy', style: 'solid' })} />
          </span>
        );

      default:
        return <FiIcons.FiMessageSquare size="40" />;
    }
  };

  if (notification.type === 'MESSAGE_RECEIVED') {
    const matches = content.match(/href="([^"]*)/);
    if (Array.isArray(matches)) {
      link = matches[1];
    }
  }

  const getNotificationTime = () => {
    const luxonDate = DateTime.fromISO(notification.createdAt);

    return luxonDate.toFormat('ff');
  };

  const onClick = link
    ? () => {
        history.push(link);
      }
    : () => {};

  return (
    <div
      className={`flex flex-row w-full py-3 border-b border-b-pursuit-gray ${link && 'cursor-pointer'}`}
      onClick={onClick}>
      <div className="flex items-center justify-center w-20 h-20 mr-4 border border-pursuit-gray text-guardian-darkblue">
        {getIcon()}
      </div>

      <div className="flex flex-col flex-1 min-h-20">
        <p className="font-bold text-guardian-darkblue">
          {notification.attributes ? notification.attributes.customTitle || notification.title : notification.title}
        </p>
        {/* <p className="mb-1 text-sm text-pursuit-gray">{notification.message}</p> */}
        <span className="mb-1 text-sm text-pursuit-gray" dangerouslySetInnerHTML={{ __html: content }} />
        <p className="w-full mt-auto text-xs text-right text-gray-300">{getNotificationTime()}</p>
      </div>
    </div>
  );
};

export default NotificationRow;
